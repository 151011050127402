import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64')
];

export const server_loads = [0,2,3,4,5,6,7,8];

export const dictionary = {
		"/": [12],
		"/_dashboard": [~14,[2]],
		"/_dashboard/account": [~15,[2,3]],
		"/_dashboard/account/[account_id]": [~16,[2,3,4]],
		"/_dashboard/account/[account_id]/withdraw": [~17,[2,3,4]],
		"/_dashboard/application/new": [~18,[2,5]],
		"/_dashboard/application/review/[application_id]": [~19,[2,5]],
		"/_dashboard/application/review/[application_id]/individual/[corp_individual_id]": [~20,[2,5]],
		"/_dashboard/fees": [~21,[2]],
		"/_dashboard/invoices": [~22,[2]],
		"/_dashboard/invoices/new": [~24,[2]],
		"/_dashboard/invoices/[id]": [~23,[2]],
		"/_dashboard/knowledge-center": [~25,[2]],
		"/_dashboard/knowledge-center/[slug]": [26,[2]],
		"/_dashboard/referrals": [~27,[2]],
		"/about": [~28],
		"/account": [29],
		"/account/update-password": [~30],
		"/auth/verify": [~31],
		"/contact": [~32],
		"/dashboard": [~33,[6]],
		"/dashboard/accounts": [~34,[6]],
		"/dashboard/accounts/[account_id]": [~35,[6,7]],
		"/dashboard/accounts/[account_id]/withdraw": [~36,[6,7]],
		"/dashboard/application": [37,[6,8]],
		"/dashboard/application/new": [~38,[6,8]],
		"/dashboard/application/review/[application_id]": [~39,[6,8]],
		"/dashboard/application/review/[application_id]/individual/[corp_individual_id]": [~40,[6,8]],
		"/dashboard/fees": [~41,[6]],
		"/dashboard/history": [~42,[6]],
		"/dashboard/invoices": [~43,[6,9]],
		"/dashboard/invoices/new": [~45,[6,9]],
		"/dashboard/invoices/[id]": [~44,[6,9]],
		"/dashboard/learn-n-earn": [46,[6,10]],
		"/dashboard/learn-n-earn/invite-friends": [~47,[6,10]],
		"/dashboard/learn-n-earn/knowledge-center": [~48,[6,10,11]],
		"/dashboard/learn-n-earn/knowledge-center/[slug]": [49,[6,10,11]],
		"/dashboard/profile": [~50,[6]],
		"/dashboard/profile/individual/[corp_individual_id]": [~51,[6]],
		"/dashboard/statements": [~52,[6]],
		"/dashboard/transactions": [~53,[6]],
		"/e-sign-disclosure-and-consent": [~54],
		"/invoice/[id]": [~55],
		"/privacy-policy": [~56],
		"/prohibited-businesses": [~57],
		"/redirect/recovery": [58],
		"/ref/[ref_id]": [~59],
		"/request-access": [~60],
		"/restricted-jurisdictions": [~61],
		"/sign-in": [~62],
		"/sign-up": [~63],
		"/terms-and-conditions": [~64],
		"/[affiliate]": [~13]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';